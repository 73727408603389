@import url("https://fonts.googleapis.com/css2?family=Grandstander:ital,wght@0,100..900;1,100..900&display=swap");

:root {
  --sm: 1.8rem;
  --md: 2rem;
  --lg: 3rem;
  --spin-duration : 300ms
}

.SpinGameLayout {
  /* background: url("/images/games/jackpotBanner.webp"); */
  background: url("/images/games/jackpotBanner.webp");
  background-position: top;
  background-repeat: no-repeat;
  background-size: 800px;
  overflow: hidden;
}

.jackpot-container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
 
}

.title,
.subtitle {
  font-family: "Grandstander", serif;
  font-weight: 900;
  font-size: var(--sm);
  margin: 0;
  padding: 0;
}

.panel-title {
  font-family: "Grandstander", serif;
  font-weight: 900;
  font-size: clamp(1.5rem, 2vw, 2.5rem);
  text-align: center;
  background: linear-gradient(180deg, 
  #652dff 40%, #d815ff 97%);
  color: transparent;
  background-clip: text;
  /* text-shadow: 2px 0px 1px #e8e8e8; */
}

.load-more-text {
  font-family: "Grandstander", serif;
  font-weight: 900;
  font-size: 1rem;
  text-align: center;
  background: linear-gradient(180deg, 
  #100333 40%, #430050b9 97%);
  color: transparent;
  background-clip: text;
  /* text-shadow: 2px 0px 1px #e8e8e8; */
}

.title {
  /* background: rgb(243,239,255); */
  background: linear-gradient(
    180deg,
    rgba(243, 239, 255, 1) 40%,
    rgba(176, 150, 245, 1) 97%
  );
  color: transparent;
  background-clip: text;
}

.subtitle {
  /* background: rgb(243,239,255); */
  background: linear-gradient(
    172deg,
    rgba(255, 205, 78, 1) 41%,
    rgba(185, 122, 12, 1) 97%
  );
  color: transparent;
  background-clip: text;
}

.subtitle > span {
  text-transform: uppercase;
}

.jackpot-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
  line-height: 1;
}

.list-card {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.6671043417366946) 0%,
    rgba(0, 0, 0, 0.6699054621848739) 40%,
    rgba(0, 0, 0, 0.577468487394958) 63%,
    rgba(0, 0, 0, 0.6334908963585435) 100%
  );
}

.game-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.jackpot-container aside {
  padding-inline: 2rem;
  position: relative;
}

.jackpotLondia {
  position: absolute;
  right: -25%;
  bottom: 0;
}

.jackpotLondia img {
  width: 80%;
}

.jackpot-container aside > .panel {
  /* background: rgb(24, 24, 24); */
  background: rgb(119, 44, 1);
  background: linear-gradient(
      90deg,
      rgba(119, 44, 1, 1) 0%,
      rgba(238, 200, 7, 1) 33%,
      rgba(199, 149, 5, 1) 69%,
      rgba(119, 44, 1, 1) 100%
    );
  height: 100%;
  border: 2px solid rgb(255, 218, 167);
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  height: 90dvh;
}

.jackpot-container .wheel-container {
  transform: translateY(30px) !important;
}

/* .spin-modal {
  height: 80% !important;
  padding: 12.5% 0;
  background-position: unset !important;
  background-size: 100% 200% !important;
} */

.spin-content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(0px);
}

.panel-list {
  max-height: calc(90dvh - 20% - 3rem) !important
}

@media screen and (min-width: 1024px) {
  .jackpot-container {
    height: 100% !important;
    grid-template-columns: 1fr 2fr 1fr !important;
  }

  .SpinGameLayout {
    /* background: url("/images/games/jackpotBanner.png"); */
    background: url("/images/games/jackpotBanTest.webp") !important;
    /* background-position: 0 0; */
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }

  .title,
  .subtitle {
    font-size: var(--lg) !important;
  }
}

@media (width > 1024px) {
  /* … */
  .jackpot-container .wheel-container {
    transform: translateY(120px) !important;
    scale: 0.9 !important;
  }

  .spin-content-wrapper {
    transform: translateY(100px) !important;
  }
}

@media (width <=786px) {
  .jackpot-mobile {
    background-image: url("/images/games/mobileJackpotFrame.webp") !important;
    background-repeat: repeat !important;
  }
}

@media (width >=1440px) {
  /* … */
  .jackpot-container .wheel-container {
    transform: translateY(200px) !important;
    scale: 1 !important;
  }

  .spin-content-wrapper {
    transform: translateY(200px) !important;
  }
}

@media (width <=425px) and (height <=700px) {
  .spin-content-wrapper {
    transform: translateY(-20px) !important;
  }
}

@media (width >=1440px) and (height <=800px) {
  /* … */
  .jackpot-container .wheel-container {
    transform: translateY(150px) !important;
    scale: 1 !important;
  }

  .spin-content-wrapper {
    transform: translateY(220px) !important;
  }
}

@media (width >=1440px) and (height <=700px) {
  .spin-content-wrapper {
    transform: translateY(180px) !important;
  }
}

@media screen and (max-height: 700px) {
  .panel-list {
    max-height: calc(90dvh - 20% - 3rem) !important
  }

  .game-container {
    height: 38rem !important;
    transform: translateY(-70px) !important;
  }

  .jackpot-center {
    height: 28rem !important;
  }
}

/* Default styles (Mobile First Approach) */
body {
  font-size: 16px !important;
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) {
  .SpinGameLayout {
    background-size: 900px !important;
  }

  .jackpotLondia img {
    width: 60% !important;
  }

  .jackpotLondia {
    right: -20% !important;
    bottom: -5% !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 992px) {
  .SpinGameLayout {
    /* background-size:100dvw;
    background-position: 0 2px; */
    /* background-size: cover; */
    background-size: 100dvw 100% !important;
    background-position: center !important;
  }

  .jackpotLondia img {
    width: 60% !important;
  }

  .jackpotLondia {
    right: -50% !important;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 900px) {
  .jackpotLondia img {
    width: 80% !important;
  }

  .jackpotLondia {
    right: -10% !important;
    bottom: 1rem !important;
  }
  .panel-list {
    max-height: calc(90dvh - 25%) !important
  }
}

@media (min-width: 1280px) and (max-height: 800px) {
  .jackpotLondia img {
    width: 60% !important;
  }

  .jackpotLondia {
    right: -35% !important;
    bottom: 1rem !important;
  }
  
}

@media (min-width: 1440px) {
  .jackpotLondia img {
    width: 70% !important;
  }

  .jackpotLondia {
    right: -20% !important;
    bottom: 1rem !important;
  }
  .panel-list {
    max-height: calc(90dvh - 20%) !important
  }
}

@media (max-width: 768px) {
  .jackpotLondia img {
    display: none !important;
  }
}

@media (min-width: 768px) and (orientation: landscape) and (max-height: 600px) {

  .panel-list {
    /* width: 90% !important; */
    margin: auto !important;
  }
  
}


@media (width >= 425px) and (width <= 900px) {
  .jackpot-container .wheel-container {
    transform: translateY(4vh) !important;
    scale: .7 !important;
  }
 
}

@media (width > 600px) and (width <= 950px) {
  .panel-list{
    /* width: 98%; */
    scale: .9;
  }

  .panel-title {
    top: 10% !important;
    font-size: clamp(2rem, 2vw, 2.5rem)
  }
 
}

.panel-list::-webkit-scrollbar {
  width: 8px;
}

.panel-list::-webkit-scrollbar-track {
  background: #ffd519;
  border-radius: 10px;
}

.panel-list::-webkit-scrollbar-thumb {
  background: #ffdf4e;
  border-radius: 10px;
}

.panel-list::-webkit-scrollbar-thumb:hover {
  background: #ffd519;
}

.panel-list {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #88888800 #f1f1f100; /* For Firefox */
}
